.like-icon path{
    cursor: pointer;
    animation: fade-in 1s cubic-bezier(.19,.73,.54,.97);
    animation-fill-mode: both;
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }