* {
  box-sizing: border-box;
}

:root {
  --gray: #181818;
}

.spotify-app {
  background-color: var(--gray);
  height: 100vh;
}
